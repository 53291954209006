
import { Form,  } from 'react-bootstrap'


type Prop ={
    label?:string,
    onChange:(value:string)=>void,
    options:{name:string,value:string}[],
    errorMessage?:string,
    icon?:any,
    placeholder?:string
}
const SelectWithLabel = ({icon,placeholder,label,options,onChange,errorMessage}:Prop)=>{

    return (
    <div>
        {
            label?
            <Form.Label htmlFor={label}>
            {
                icon?
                <img
                src={icon}
                alt="worldIcon"
                style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '10px',
                }}
            />:''
            }
      
            {label}
        </Form.Label>:''
        }

    <Form.Group className="mb-2">
    <Form.Select
        id={label}
        // name="country"
        // value={formikStep1.values.country}
        // onChange={formikStep1.handleChange}
        // onBlur={formikStep1.handleBlur}
        onChange={e=>{
            if(onChange){
                onChange(e.target.value)
            }
        }}
    >
        <option value="">{placeholder||'Select an item'}</option>
        {
            options.map(option=>(
                <option value={option.value}>{option.name}</option>

            ))
        }
    </Form.Select>

    {
        errorMessage ? 
            <div className="error">
                {errorMessage}
            </div>
        : null}
    
    </Form.Group> 

    </div>
    )
}

export default SelectWithLabel;