import send from '../../assets/send.png'
import SelectWithLabel from '../../components/form/CustomhookFormFields/SelectWithLabel';
import Search from '../../components/form/search/Search';
import icon from '../../assets/Icon.png'

import { FaFilter, FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { LuFilter } from "react-icons/lu";




const EndPointStatusPage = ()=>{


    return (
        <div className='px-4' >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>Endpoint Status</h5>
                            <div
                            style={{display:'flex','gap':'1rem','alignItems':'center'}}
                            >
                                <Search
                                onSearchFunc={(value)=>{
                                    //
                                }}
                                />

                        <button className='py-[11px] px-[5.5px]'
                                style={{
                                    padding:'11px 5.5px',
                                    display:'flex',
                                    gap:'8px',alignItems:'center',
                                    border:'1px solid #E6E6E6',
                                    backgroundColor:'white',
                                    borderRadius:'8px'
                                }}
                                > 
                                    <LuFilter 
                                    size={20}
                                    />
                                    <span>Filter Endpoints</span>
                                </button>

                                <button className='py-[11px] px-[5.5px]'
                                style={{
                                    padding:'11px 5.5px',
                                    display:'flex',
                                    gap:'8px',alignItems:'center',
                                    border:'1px solid #E6E6E6',
                                    backgroundColor:'#F4F4F4',
                                    borderRadius:'8px'
                                }}
                                > 
                                    <FaRegStar size={20} />
                                    <span>View Favourites</span>
                                </button>
                                <SelectWithLabel
                                placeholder='Select Country'
                                                icon={icon}
                                               options={[
                                                {'name':'Nigeria','value':'Nigeria'},
                                                {'name':'Ghana','value':'Ghana'}
                                               ]}
                                                onChange={value=>{
                                                    //
                                                }}
                                                />

                            </div>
                        </div>
                        <br /><br />

                        <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">ENDPOINT NAME</th>
                                        <th scope="col">STATUS</th>
                                        <th scope="col">COUNTRY</th>
                                        <th scope="col">TIME UPDATED</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...new Array(2)].map((d,index)=>(
                                            <tr
                                            //  key={index}
                                             className="api-item">
                                                        <td>
                                                           <FaStar  size={20} style={{fill:'#FEB201',transform:'translateY(4px) translateX(10px)'}}/>
                                                        </td>
                                                        <td>
                                                            <div className="cell-content endpoint-name"
                                                           
                                                            >
                                                                <span className="endpoint-first-letter"
                                                                 style={{
                                                                    backgroundColor:'#DAF1EE',borderRadius:'4px'
                                                                }}
                                                                >
                                                                   A
                                                                </span>
                                                                Access Bank Address Verification Status
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="cell-content">
                                                                <span
                                                                    className={`status
                                                                     
                                                                    published
                                                                    `}>
                                                                        {/* ${
                                                                        item.status === 'PUBLISHED'
                                                                            ? 'published'
                                                                            : 'DRAFTS'
                                                                    } */}
                                                                    Online
                                                                </span>
                                                            </div>
                                                        </td>
            
                                                        <td>
                                                        NG
                                                        </td>
                                                        <td>
                                                        31st June, 2024 at 4:13 AM (GMT+1)
                                                        </td>
            
            
                                                    </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
        </div>
    )
}

export default EndPointStatusPage;