import React, { useEffect, useState } from 'react'
import Mainloader, { EmptyWidgetStateComp, PendingTag, SuccessTag } from '../../../utils'
import { Spinner } from 'react-bootstrap'
import NotificationToast from '../../../utils/notifToast'
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Moment from 'react-moment'
import {
    sdkCreateRequest,
    sdkDeleteSingleRequest,
    sdkEndpointsInfoRequest,
    sdkInfoAllRequest,
} from '../../../../redux/actions/sdkLibraries'
import { RootState } from '../../../../redux/reducers'
import MoreIcon from '../../../../assets/more.svg'
import { copyTextToClipboard, removeAllQueryParam } from '../../../../utils'
import Modal from '../../../modal/Modal'
import { generateSdkUrlIDApi, switchSDKEnvApi } from '../../../../redux/sagas/apiLibraries/application'
import axios from 'axios'
import global from '../../../../redux/constants/global'

export function generateUniqueRandomNumberString() {
    const numbers = '0123456789'.split('');
    for (let i = numbers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [numbers[i], numbers[j]] = [numbers[j], numbers[i]];
    }
    return numbers.join('').substring(0, 10);
}
const SwitchWidgetEnviroment = ({config_id,onSuccess}:{config_id:string,onSuccess:(resp:any)=>void})=>{
    // @ts-ignore
    const [widgetMode,setWidgetMode] = useState<'LIVE'|'SANDBOX'>('SANDBOX');
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState('');
    // 
    const switchWidget =  async ()=>{
        if(!widgetMode){
            setError('Please Select Environment')
            return 
        }
        setIsLoading(true)
        try {

            const resp = await switchSDKEnvApi({config_id,mode:widgetMode})
            // console.log({resp})
            onSuccess(resp)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }

    }
    return (
        <div>

            <div className="">
                <label htmlFor="env">
                    Environment <span style={{ color: 'red' }}> *</span>
                </label>
                <select
                    className="form-select"
                    // @ts-ignore
                    onChange={(e) => setWidgetMode(e.target.value)}
                    value={widgetMode}>
                    <option value="">Select Environment</option>
                    <option value="LIVE">Live Environment</option>
                    <option value="SANDBOX">Sandbox Environment</option>
                </select>
                <span style={{'color':'crimson','fontSize':'.8rem'}}>{error}</span>
            </div>
            <button
                className="btn btn-deep-green py-2 mt-2 w-100"
                
                onClick={switchWidget}>
                {isLoading ? (
                    <div>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    'Switch Environment'
                )}
            </button>
        </div>
    )
}
const CopyWigetGenerator =({config_id,onSuccess,mode}:{mode:string,config_id:string,onSuccess:(resp:any)=>void})=>{

    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [unique,setUnique] = useState('');
    const [isLoading,setIsLoading] = useState(false);

    const organisationInfoState = useSelector((state: RootState)=> state.organisationInfoReducer);

    const onSubmit = async ()=>{
        // 
        setIsLoading(true)
        try{
            const resp = await generateSdkUrlIDApi({
                    "email":organisationInfoState?.resp?.data?.user?.email,
                    "first_name":organisationInfoState?.resp?.data?.user?.first_name,
                    "last_name" :organisationInfoState?.resp?.data?.user?.last_name,
                    "user_ref":generateUniqueRandomNumberString(),
                    "config_id":config_id,
                    "mode": mode
            })
            onSuccess(resp)
            setIsLoading(false)
        }catch(err:any){
            setIsLoading(false)
        }
    }
    return (
        <div>
            {JSON.stringify(organisationInfoState)}
        <div className="">
                                <label htmlFor="name">
                                    First Name <span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    value={firstName}
                                    required
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="">
                                <label htmlFor="name">
                                    Last Name <span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    value={lastName}
                                    required
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="">
                                <label htmlFor="name">
                                    Email<span style={{ color: 'red' }}> *</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter widget Email"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>



                            <button
        className="btn btn-deep-green py-2 mt-2 w-100"
        
        onClick={onSubmit}
        >
             {isLoading ? (
                    <div>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    'Generate Widget Test Link'
                )}
            
    </button>
        </div>
    )
}
export default function SDKListsComp() {
    const navigate = useNavigate()
    const { pathname } = useLocation()
   
    const [notifVal, setNotifVal] = useState(false)
    const [notif, setNotif] = useState('')
    const [notifTitle, setNotifTitle] = useState('')
    const [isLoading,setIsLoading] = useState(false);

    const [createModal, setCreateModal] = useState(false)
    const [widgetName, setWidgetName] = useState('')
    const [widgetMode, setWidgetMode] = useState('')
    const [productName, setProductName] = useState('')
    const [isDeleteWidget, setIsDeleteWidget] = useState(false)

    const [selectedAction, setSelectedAction] = useState<any>({
        isOpen: null,
        id: null,
    })
    const organisationInfoState = useSelector((state: RootState)=> state.organisationInfoReducer);
    
    const sdkCreateState = useSelector((state: RootState) => state.sdkCreateReducer)
    const getCreatedSdks = useSelector((state: RootState) => state.sdkInfoAllReducer)
    const deleteSingleWidget = useSelector((state: RootState) => state.sdkDeleteSingleReducer)

    console.log(getCreatedSdks, 'get')

    const dispatch = useDispatch()

    useEffect(() => {
        getAvailableCheckerEndpoints()
    }, [])

    useEffect(() => {
        getAvailableChecker()
    }, [])

    let getAvailableCheckerEndpoints = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(sdkEndpointsInfoRequest(data))
    }

    let getAvailableChecker = () => {
        const callback = (data: any) => {
            if (!data.status) {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {},
            callback,
        }
        dispatch(sdkInfoAllRequest(data))
    }

    let createWidget = () => {
        if (!widgetName) {
            setNotifTitle('Error')
            setNotif('Please enter a widget name')
            setNotifVal(true)
            return
        }
        //  else if (!widgetMode) {
        //     setNotifTitle('Error')
        //     setNotif('Please select an environment')
        //     setNotifVal(true)
        //     return
        // }

        const callback = (data: any) => {
            if (data.status) {
                console.log(data, 'home')
                navigate(`/SDK-Library/Config/${data.widget_id}`)
            } else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {
                name: widgetName,
                widget_mode: 'SANDBOX',
                product: productName,
            },
            callback,
        }
        dispatch(sdkCreateRequest(data))
    }

    let deleteWidget = () => {
        console.log('clicked')
        // if (!widgetName) {
        //     setNotifTitle('Error')
        //     setNotif('Please enter a widget name')
        //     setNotifVal(true)
        //     return
        // } else if (!widgetMode) {
        //     setNotifTitle('Error')
        //     setNotif('Please select an environment')
        //     setNotifVal(true)
        //     return
        // }

        const callback = (data: any) => {
            if (data.status) {
                console.log(data)
                getAvailableChecker()
                setIsDeleteWidget(false)
                setNotifTitle('Success')
                setNotif(data.detail)
                setNotifVal(true)
            } else {
                setNotifTitle('Error')
                setNotif(data.detail)
                setNotifVal(true)
            }
        }
        let data: any = {
            values: {
                // name: widgetName,
                config_id: selectedAction.id,
            },
            callback,
        }
        console.log(data, 'sem')
        dispatch(sdkDeleteSingleRequest(data))
    }

    const handleToggleMenu = (sdk: any) => {
        setSelectedAction({
            ...selectedAction,
            isOpen: !selectedAction.isOpen,
            id: sdk.id,
        })
    }

    const handleEditSDK = () => {
        navigate(`${pathname}/${selectedAction.id}`)
    }

    const handleOpenDeleteWidgetModal = () => {
        setIsDeleteWidget(!isDeleteWidget)
    }
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(()=>{ 
        const notifyMessage = searchParams.get('notify')
        if(notifyMessage){
            setNotifVal(true)
            setNotifTitle('Config Update')
            setNotif('You config has been save successfully')
            removeAllQueryParam()
        }
    },[])
    const [openEnvSwitch,setOpenEnvSwitch] = useState(false);
    const [openWidgetCopy,setOpenWidgetCopy] = useState(false);
    const [configId,setSelectedConfigid] = useState(null)
    const [mode,setSelectedMode] = useState<any>(null)



    return (
        <div className='px-4' >
            {notif && notifVal && (
                <NotificationToast
                    title={notifTitle}
                    message={notif}
                    closeNotif={() => setNotifVal(!notifVal)}
                />
            )}

            <Modal
            
            open={openEnvSwitch}
            setOpen={setOpenEnvSwitch}
            modalTitle="Switch Environment"
            modalBoxstyle={{'height':'unset'}}
            >
                <div>
                {
                    typeof configId == 'string'?
                    <SwitchWidgetEnviroment 
                    onSuccess={(resp)=>{
                        // detail
                        setOpenEnvSwitch(false)
                        setNotifVal(true)
                        setNotifTitle('Config Enviroment Switch')
                        setNotif(resp.detail)
                        getAvailableChecker()

                    }}
                    config_id={configId}
                    />:''
                }
                </div>
            </Modal>


            <Modal
            
            open={openWidgetCopy}
            setOpen={setOpenWidgetCopy}
            modalTitle="Copy test URL"
            modalBoxstyle={{'height':'unset'}}
            >
                <>
                    {
                        (configId&&mode)?
                        <CopyWigetGenerator 
                        mode={mode}
                        config_id={configId}
                    onSuccess={resp=>{
                        setNotifTitle('Success')
                setNotif('Widget Generated.')
                setNotifVal(true)
                if(window.location.hostname.includes('dev')){
                    window.open(`https://d134s5jj8i8a8y.cloudfront.net/index.html?ref=${resp.url_widget_id}`,'_blank')
                }else{
                    window.open(`https://widget-preview.prembly.com/index.html?ref=${resp.url_widget_id}`,'_blank')
                }
                        setOpenWidgetCopy(false)
                    }}
              />:''
                    }
                </>
        
            </Modal>

            {/* {organisationInfoState?.isLoading ? props.changeLoadingState(true) : props.changeLoadingState(false)} */}

            {createModal && (
                <div className="main-modal">
                    <div className="main-modal-content card col-md-7 col-lg-4 mx-auto">
                        <span onClick={() => setCreateModal(false)}>
                            <i className="ri-close-line close-modal"></i>
                        </span>
                        <div className="card-body">
                            <div className="main-modal-body">
                                <div className="main-modal-header col-md-5 col-lg-5">
                                    <h5>Create Widget</h5>
                                </div>

                                <div>
                                    {/* <div className="">
                                        <label htmlFor="env">
                                            Environment <span style={{ color: 'red' }}> *</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setWidgetMode(e.target.value)}
                                            value={widgetMode}>
                                            <option value="SANDBOX">Sandbox Environment</option>
                                            <option value="LIVE">Live Environment</option>
                                        </select>
                                    </div> */}
                                    <div className="">
                                        <label htmlFor="env">
                                            Product <span style={{ color: 'red' }}> *</span>
                                        </label>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setProductName(e.target.value)}
                                            value={productName}>
                                            <option value="">Select Product</option>
                                            <option value="IDENTITYPASS">IdentityPass</option>
                                            <option value="IDENTITYRADAR">IdentityRadar</option>
                                        </select>
                                    </div>
                                    <div className="">
                                        <label htmlFor="name">
                                            Widget Name <span style={{ color: 'red' }}> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter widget name"
                                            value={widgetName}
                                            onChange={(e) => setWidgetName(e.target.value)}
                                        />
                                    </div>

                                    {/* <Link to="/SDK-Library/Config/123"> */}
                                    <button
                                        className="btn btn-deep-green py-2 mt-5 w-100"
                                        onClick={createWidget}>
                                        {sdkCreateState.isLoading ? (
                                            <div>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Create'
                                        )}
                                    </button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(getCreatedSdks.isLoading||isLoading) && <Mainloader />}
            {(!getCreatedSdks.isLoading || !isLoading)  &&
                getCreatedSdks?.resp?.data?.length !== 0 &&
                getCreatedSdks?.resp?.data !== null && (
                    <div
                    //  className="container-fluid px-md-4 my-4"
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h5>All Checker Widgets</h5>
                            <div>
                                <button
                                    className="btn btn-deep-green py-2 "
                                    onClick={() => setCreateModal(true)}>
                                    Create New Widget
                                </button>
                            </div>
                        </div>
                <div className=" mt-4 table-responsive">
                            <table className="table policy-table">
                                <thead className="">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">NAME</th>
                                        {/* <th scope="col">WIDGET CODE</th> */}
                                        <th scope="col">ENVIRONMENT</th>
                                        <th scope="col">CREATED BY</th>
                                        <th scope="col">DATE CREATED</th>
                                        <th></th>
                                    </tr>
                                </thead>
          <div  style={{'marginTop':'1rem'}}></div>

                                <tbody>
                                    {getCreatedSdks?.resp?.data?.map((sdk: any, index: number) => (
                                        <>
                                                                                <tr
                                        style={{'backgroundColor':'white',
                                        border:'1px solid #F2F2F2',borderRadius:'1rem','cursor':'pointer',
                                        padding:'0 3rem',
                                        // position:'relative',
                        
                                    }}
                                        >
                                            <td>{index + 1}</td>
                                            <td>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 5,
                                                    }}>
                                                    <div
                                                        style={{
                                                            background: sdk.theme_color,
                                                            height: '20px',
                                                            width: '20px',
                                                        }}
                                                    />
                                                    {sdk.name}
                                                </div>
                                            </td>
                                            {/* <td style={{'textWrap':'nowrap'}}>
                                            {sdk.id}
                                            <img 
                        style={{'display':'inline-block','marginLeft':'5px'}}
                         src="/copy.svg" alt=""
                         onClick={()=>{
                            setNotifVal(true)
                            setNotifTitle('Copied To Clipboard')
                            setNotif('Copied To Clipboard')

                            copyTextToClipboard(sdk.id)
                         }}
                         />
                                            </td> */}
                                            <td>{
                                                sdk.widget_mode==='LIVE'?
                                                <SuccessTag name={sdk.widget_mode} />:
                                                <PendingTag name={sdk.widget_mode} />
                                                }
                                            </td>
                                            <td>{sdk.created_by}</td>
                                            <td style={{'textWrap':'wrap'}}>
                                                <Moment format="D MMM YYYY,  hh:mm A" withTitle>
                                                    {sdk.created_at}
                                                </Moment>
                                            </td>
                                            <td
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'relative',
                                                }}
                                                onClick={() => handleToggleMenu(sdk)}>
                                                    {
                                                        !isLoading?
                                                        <img
                                                        src={MoreIcon}
                                                        alt="more-icon"
                                                        style={{ width: '20px' }}
                                                    />:''
                                                    }

                                                {selectedAction.id === sdk.id &&
                                                    selectedAction.isOpen && (
                                                        <div
                                                            style={{
                                                                left: '-80px',
                                                                // width: 'auto',
                                                                position: 'absolute',
                                                                zIndex: 1,
                                                                borderRadius: '8px',
                                                                // padding: '20px 24px',
                                                                width:'151px',

                                                                boxShadow:
                                                                    '4px 2px 4px 3px rgba(0, 0, 0, 0.1)',
                                                                background:
                                                                    'rgba(255, 255, 255, 1)',
                                                                    transform:'translateX(-40px)'

                                                            }}>
                                                            <p
                                                                  style={{
                                                                    padding:'0.6rem',borderBottom:'.9px solid #E3E3E3',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#0B0A0A',display:'flex','alignItems':'center',gap:'0.5rem'
                                                                  }}
                                                                // className="d-flex align-items-center pb-3 border-bottom"
                                                                onClick={handleEditSDK}>
                                                                    <img src="/edit-2.svg" alt="" />
                                                                {/* <i className="ri-eye-line me-2 ri-xl" /> */}
                                                                Edit
                                                            </p>
                                                            <p
                                                                  style={{
                                                                    padding:'0.6rem',borderBottom:'.9px solid #E3E3E3',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#0B0A0A',display:'flex','alignItems':'center',gap:'0.5rem'
                                                                  }}
                                                                // className="d-flex align-items-center pb-3 border-bottom"
                                                                onClick={()=>{
                                                                    window.open('https://docs.prembly.com/docs/prembly-sdk','_blank')
                                                                }}>
                                                                    <img src="/eye-fill.png" alt="" />
                                                                View doc
                                                            </p>
                                                            <p
                                                                  style={{
                                                                    padding:'0.6rem',borderBottom:'.9px solid #E3E3E3',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#0B0A0A',display:'flex','alignItems':'center',gap:'0.5rem'
                                                                  }}
                                                                // className="d-flex align-items-center pb-3 border-bottom"
                                                                onClick={()=>{
                                                                    setSelectedConfigid(sdk.id)
                                                                    setOpenEnvSwitch(true)
                                                                }}>
                                                                    <img src="/restart-line.png" alt="" />
                                                                <span>Switch Env</span>
                                                            </p>

                                                            <p
                                                                  style={{
                                                                    padding:'0.6rem',borderBottom:'.9px solid #E3E3E3',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#0B0A0A',display:'flex','alignItems':'center',gap:'0.5rem'
                                                                  }}
                                                                // className="d-flex align-items-center pb-3 border-bottom"
                                                                onClick={ async () =>{
                                                                    // setSelectedConfigid(sdk.id)
                                                                    // setSelectedMode(sdk.widget_mode)
                                                                    // setOpenWidgetCopy(true)

                                                                    setIsLoading(true)
                                                                    try{
                                                                        const resp = await generateSdkUrlIDApi({
                                                                                // "email":organisationInfoState?.resp?.data?.user?.email,
                                                                                // "first_name":organisationInfoState?.resp?.data?.user?.first_name,
                                                                                // "last_name" :organisationInfoState?.resp?.data?.user?.last_name,
                                                                                "email":`dummy${mode}@prembly.com`,
                                                                                "first_name":'prembly',
                                                                                "last_name" :'test',
                                                                                "user_ref":generateUniqueRandomNumberString(),
                                                                                "config_id":sdk.id,
                                                                                "mode": sdk.widget_mode
                                                                        })
                                                                        setIsLoading(false)
                                                                        if(window.location.hostname.includes('dev')){
                                                                            window.open(`https://d134s5jj8i8a8y.cloudfront.net/index.html?ref=${resp.url_widget_id}`,'_blank')
                                                                        }else{
                                                                            window.open(`https://widget-preview.prembly.com/index.html?ref=${resp.url_widget_id}`,'_blank')
                                                                        }



                                                                    }catch(err:any){
                                                                        setIsLoading(false)
                                                                    }
                                                                }}>
                                                                    {
                                                                        isLoading?
                                                                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />:
                        <img src="/clipboard-line.png" alt="" />

                                                                    }
                                                                {/* <i className="ri-eye-line me-2 ri-xl" /> */}
                                                                <span style={{'display':'block','textWrap':'nowrap'}}>
                                                                Test widget
                                                                </span>
                                                            </p>

                                                            <p
                                                                  style={{
                                                                    padding:'0.6rem',borderBottom:'.9px solid #E3E3E3',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#0B0A0A',display:'flex','alignItems':'center',gap:'0.5rem',
                                                                    textWrap:'nowrap'
                                                                  }}
                                                                // className="d-flex align-items-center pb-3 border-bottom"
                                                                onClick={()=>{
                                                                    setNotifVal(true)
                                                                    setNotifTitle('Copied To Clipboard')
                                                                    setNotif('Widget Code Copied To Clipboard')
                                        
                                                                    copyTextToClipboard(sdk.id)
                                                                }}>
                                                                    <img src="/clipboard-line.png" alt="" />
                                                                {/* <i className="ri-eye-line me-2 ri-xl" /> */}
                                                                <span>Copy  Code</span>
                                                            </p>
                                                            
                                                            <p
                                                                // className="d-flex align-items-center pb-3 mb-0 border-bottom text-danger"
                                                                // onClick={handleAdoptPolicy}
                                                                style={{
                                                                    padding:'0 0.6rem',cursor:'pointer',
                                                                    fontFamily:'--general-sans-medium',fontWeight:'500','fontSize':'0.875rem',
                                                                    color:'#FC7D73',
                                                                    display:'flex','alignItems':'center',gap:'0.5rem'
                                                                  }}
                                                                onClick={
                                                                    handleOpenDeleteWidgetModal
                                                                }>
                                                                    <img src="/red_trash.svg" alt="" />
                                                                {/* <i className="ri-delete-bin-7-line me-2 ri-xl"></i> */}
                                                                <span>Delete</span>
                                                            </p>
                                                        </div>
                                                    )}
                                            </td>
                                        </tr>
                        <div style={{'marginBottom':'.5rem'}}></div>

                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div> 
          
                    </div>
                )}

            {!getCreatedSdks.isLoading && getCreatedSdks?.resp?.data?.length === 0 && (
                <div className="col-md-4 mx-auto mt-5">
                    <EmptyWidgetStateComp
                        title={'Configure your SDK with ease'}
                        ctaAction={() => {
                            setCreateModal(true)
                        }}
                        desc={
                            'Create your first widget by clicking the button and see a summary here'
                        }
                        ctaValue={'Create Widget'}
                    />
                </div>
            )}
            {isDeleteWidget && (
                <div className="main-modal ">
                    <div className="main-modal-content card col-md-5 col-lg-3 mx-auto">
                        <span
                            onClick={() => {
                                // setAppName('')
                                // setDeactivateModal(false)
                                setIsDeleteWidget(false)
                            }}>
                            <i className="ri-close-line close-modal" />
                        </span>
                        <div className="card-body">
                            <div className="main-modal-body">
                                <div className="text-center mt-4">
                                    <h5>Are you sure you want to delete this widget?</h5>
                                    <p>
                                        You will have to reconfigure your settings again if you
                                        delete this widget{' '}
                                    </p>

                                    <button
                                        className="btn btn-deep-green py-2 mt-3 w-100"
                                        onClick={deleteWidget}>
                                        {deleteSingleWidget.isLoading ? (
                                            <div>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            'Yes, Delete this widget'
                                        )}
                                    </button>
                                    <button
                                        className="btn btn-deep-green-outline py-2  mt-3 me-3 w-100"
                                        onClick={() => {
                                            // setAppName('')
                                            // setDeactivateModal(false)
                                        }}>
                                        No, Go Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
